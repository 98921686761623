import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { Loading } from '@/components/common/Loading'
import { useSession } from '@/hooks/auth/useSession'
import type { NextPage } from 'next'

const Home: NextPage = () => {
  const router = useRouter()
  const { session, isLoading } = useSession()

  useEffect(() => {
    if (isLoading) return
    if (session && session.valid) {
      router.replace('/dashboard')
    } else {
      router.push('/auth/login')
    }
  }, [isLoading, session, router])

  return <Loading />
}

export default Home
